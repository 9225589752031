/* font-family: 'Cookie', cursive;
font-family: 'Vidaloka', serif;
font-family: 'Abel', sans-serif;
font-family: 'Raleway', sans-serif; */


.filter-time {
    margin-top: 70px;
    overflow-y: auto;
    height: 100%;
}

.filter-time h2 {
    color: white;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 20px;
    font-family: 'Cookie', cursive;
    font-size: 60px;
}
.select-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}

.filter-time button {
    height: 40px;
    width: 70px;
    margin-left: 5px;
    background: rgba(92, 146, 85, 0.856);
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 550;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border: 0px; 
    border-radius: .5em;
}
.filter-time button:hover {
    background: rgb(92, 146, 85);
    transition-duration: 0.4s;
}

h3 {
    font-size: 32px;
    letter-spacing: 1px;
    margin: 0;
    padding: 10px;
}

h4{
    font-size: 16px;
    margin: 0;
    padding: 10px;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: block;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color:grey;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 15%;
    height: 40px;
    box-sizing: border-box;
   
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
}

.recipe-links-container a {
    color: white;
    font-family: 'Vidaloka', serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background: rgba(92, 146, 85, 0.856);
    margin: 10px auto;
    padding: 10px;
    border-radius: 5px;
}

.recipe-links-container a:hover {
    background: rgb(92, 146, 85);
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    transition-duration: 0.4s;
}