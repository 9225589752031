/* * {
    font-family: 'Raleway', sans-serif;
} */

.login-container form{
    color: white;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container form button {
    background: rgba(92, 146, 85, 0.856);
    color: white;
    padding: 5px 25px;
    text-align: center;
    text-decoration: none;
    font-family: 'Raleway', sans-serif;
    font-weight: 550;
    font-size: 20px;
    margin: 25px 2px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border-radius: 40px;
    border: solid white 1.1px;
}
.login-container form button:hover {
    color: rgba(92, 146, 85, 0.856);
    background: white;

}

.login-container p {
    margin: 0 auto;
    padding-bottom: 8px;
    font-family: 'Raleway', sans-serif;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 30vw;
    height: 40vh;
    background: rgba(92, 146, 85, 0.856);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    border-radius: 18px;

    font-size: 30px;
    color: white;
}
main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

#user_name, #password {
    width: 22vw;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 0px;
    height: 35px;
    border-radius: 5px;
    border: 0;
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    padding-left: 15px;

}

.login-error {
    color: red;
    font-size: 15px;
    padding: 10px;
}