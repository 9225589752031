/* font-family: 'Cookie', cursive;
font-family: 'Vidaloka', serif;
font-family: 'Abel', sans-serif;
font-family: 'Raleway', sans-serif; */

.navbar {
    height: 55px;
    background-color: rgb(40, 36, 41);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
}


.navbar a {
    color: white;
    font-family: 'Raleway', sans-serif;    
    font-size: 20px;
    margin-right: 10px;
    padding: 5px 10px;
    transition-duration: 0.2s;

}

.navbar a:hover {
    background: rgba(92, 146, 85, 0.856);
    border-radius: 5px;
}